import * as React from 'react';
import Nav from '../components/Nav';
import { useTranslation } from 'react-i18next';
import SwitchLanguage from '../components/SwitchLanguage';
import { useContext } from 'react';
import { useAuth } from '../context/auth-context';
import Widget from '../components/Widgets';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import { TextField, Button } from '@mui/material';
import axios from 'axios';

import { useEffect, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Link } from '@mui/material';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Loading from '../components/Loading';
import NotificationBox from '../components/NotificationBox';
import TaskGridData from '../components/TaskGridData';

export default function Home() {
  document.title = 'Leadtool v3 | Emeis Belgium';

  const { t } = useTranslation();

  const { state } = useAuth();
  const keycloak = state.context?.keycloak;

  const NameCap =
    state?.context?.profile?.username.charAt(0).toUpperCase() +
    state?.context?.profile?.username.slice(1);

  const [loadingData, setLoadingData] = useState(true);

  let alignmentValue = 'all';
  const [namePop, setNamePop] = useState({});
  const [surnamePop, setSurnamePop] = useState({});

  const [alignment, setAlignment] = useState('all');
  const [sortModel, setSortModel] = useState([
    {
      field: 'created_at',
      sort: 'desc',
    },
  ]);

  function changeSorting(alignmentValue) {
    const newSortModel = [];

    if (alignmentValue !== 'all') {
      newSortModel.push({
        field: 'expired_at',
        sort: 'asc',
      });
    }

    newSortModel.push({
      field: 'created_at',
      sort: 'desc',
    });

    setSortModel(newSortModel);
  }

  console.log(sortModel);

  const [openAlert, setOpenAlert] = useState(false);

  const [type, setType] = useState({});
  const [id, setId] = useState({});
  const [taskType, setTaskTypes] = useState({});
  let a = moment();
  let aTomorrow = a.clone().add(1, 'days');
  const [date, setDate] = useState(aTomorrow);

  const [data, setData] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  function handleClose() {
    setOpen(false);
    setLeads([]);
    setInputValue(''); // resets the input value when modal is closed
  }

  const [openTime, setOpenTime] = useState(false);
  const handleOpenTime = () => setOpenTime(true);
  const handleCloseTime = () => setOpenTime(false);

  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState('');
  const [openMessageText, setMessageText] = useState(
    'Check if handle? If yes, have you the access rights?'
  );
  const [errorCode, setErrorCode] = useState();
  const [openMessageFull, setMessageFull] = useState('');

  const [displayDate, setDisplayDate] = useState(false);

  const [taskNumber, SetTaskNumber] = useState({});

  async function getData() {
    await axios
      .get(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          'tasks?completed=false',
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        const todayMoment = moment();
        const tomorrowMoment = todayMoment.clone().add(0, 'days');
        const tomorrow = tomorrowMoment._d.toISOString().split('T')[0];

        const afterTomorrowMoment = todayMoment.clone().add(1, 'days');
        const afterTomorrow = afterTomorrowMoment._d
          .toISOString()
          .split('T')[0];

        const weekMoment = todayMoment.clone().add(7, 'days');
        const week = weekMoment._d.toISOString().split('T')[0];

        if (alignmentValue === 'all') {
          setData(response.data);
        }

        if (alignmentValue === 'today') {
          setData(
            response.data.filter(
              (item) => item.expired_at.split('T')[0] === tomorrow
            )
          );
        }

        if (alignmentValue === 'tomorrow') {
          setData(
            response.data.filter(
              (item) => item.expired_at.split('T')[0] === afterTomorrow
            )
          );
        }

        if (alignmentValue === 'week') {
          setData(
            response.data.filter(
              (item) => item.expired_at.split('T')[0] <= week
            )
          );
        }

        setLoadingData(false);
        setNumberTaskUrgent(
          response.data.filter(
            (item) =>
              item.expired_at.split('T')[0] <=
              moment().toISOString(new Date()).split('T')[0]
          ).length
        );
      })
      .catch((error) => {
        console.error(error);
        setOpenMessage(true);
        setSeverity('error');
        setMessageText('There was an error retrieving the data');
      });
  }

  useEffect(() => {
    if (loadingData) {
      getData();
    }
  }, []);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const cancelBtn = document.querySelector('.tasks__cancel');
  if (cancelBtn) {
    cancelBtn.addEventListener('click', () => {
      setOpenMessage(false);
    });
  }

  // Widget data

  let [numberComplaint, setNumberComplaint] = useState(0);
  let [numberLead, setNumberLead] = useState(0);
  let [numberQuestion, setNumberQuestion] = useState(0);
  let [numberTaskUrgent, setNumberTaskUrgent] = useState(0);

  let dateTask = 0;

  useEffect(() => {
    axios
      .get(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          'registrations',
        {
          params: {
            locked: false,
          },
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        setNumberQuestion(
          response.data.filter(
            (item) => item.registration_type === 'GENERAL_QUESTION'
          ).length
        );
        setNumberLead(
          response.data.filter(
            (item) => item.registration_type === 'RESERVATION_URGENT'
          ).length
        );
        setNumberComplaint(
          response.data.filter((item) => item.registration_type === 'COMPLAINT')
            .length
        );
      });
  }, []);

  const postData = (event) => {
    event.preventDefault();
    let idTask = selectedRowData.id;
    dateTask = date;
    axios
      .post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          'tasks',
        {
          registration_id: idTask,
          type: {
            name: type,
            expired_at: dateTask,
          },
        },
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        setOpenMessage(false); // Add this line
        setSeverity('success');
        setOpenMessage(true);
        setMessageText('New task added');
        setTimeout(() => {
          setOpenMessage(false);
        }, 5000);
        getData();

        setTimeout(() => {
          setOpenAlert(false);
          handleClose();
          setSortModel([
            {
              field: 'created_at',
              sort: 'desc',
            },
          ]);
          setAlignment('all');
        }, 1000);
      })
      .catch(function (error) {
        setOpenMessage(false); // Add this line
        setSeverity('error');
        setOpenMessage(true);
        setMessageText(error.response.data.error_description);
        setErrorCode(error.response.data.error_code);

        if (
          error.response.data.errors?.length > 0 &&
          error.response.data.errors[0]?.msg
        ) {
          setMessageFull(error.response.data.errors[0].msg);
        } else {
          setMessageFull('');
        }
      });
  };

  function defer(taskID) {
    axios
      .post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `tasks/${taskID}/defer`,
        JSON.stringify({}),
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((reponse) => {
        getData();
      });
  }

  function deferSpecific(taskID) {
    axios
      .post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `tasks/${taskID}/defer`,
        {
          expired_at: date,
        },
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((reponse) => {
        getData();
        handleCloseTime();
      });
  }

  function taskCompleted(taskID, taskName) {
    axios
      .post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `tasks/${taskID}/complete`,
        JSON.stringify({}),
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        getData();
        if (taskName !== 'ADMISSION_DATE') {
          handleOpen();
        }
      });
  }

  useEffect(() => {
    axios
      .get(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `tasks/types`,
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        setTaskTypes(response.data);
      });
  }, []);

  const handleChange = (newValue) => {
    setDate(newValue);
  };

  const columns = [
    {
      field: 'actions',
      headerName: t('tasks-th-actions'),
      type: 'number',
      minWidth: 200,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      editable: false,
      renderCell: (params) => {
        if (params.row.type.deferral_duration == null) {
          return (
            <div class="tasks__cell">
              <button
                type="submit"
                className="handle incharge"
                onClick={() => {
                  taskCompleted(params.row.id, params.row.type.name);
                  setId(params.row.registration_id);
                  setNamePop(params.row.registration.caller_name);
                  setSurnamePop(params.row.registration.caller_firstname);
                  setAlignment('all');
                  setStep(2);
                }}
              >
                <span className="incharge__text">{t('done')}</span>
              </button>

              <button
                className="date"
                onClick={() => {
                  handleOpenTime();
                  SetTaskNumber(params.row.id);
                  let a = moment(params.row.expired_at);
                  if (a < moment()) {
                    setDate(aTomorrow);
                    console.log(a);
                  } else {
                    setDate(
                      a.clone().add(1, 'days')._d.toISOString().split('T')[0]
                    );
                  }
                }}
              >
                <span className="date__text">Date</span>
              </button>
            </div>
          );
        } else {
          return (
            <div class="tasks__cell">
              <button
                type="submit"
                className="handle incharge"
                onClick={() => {
                  taskCompleted(params.row.id);
                  setId(params.row.registration_id);
                  setNamePop(params.row.registration.caller_name);
                  setSurnamePop(params.row.registration.caller_firstname);
                  setAlignment('all');
                }}
              >
                <span className="incharge__text">{t('done')}</span>
              </button>

              <button className="report" onClick={() => defer(params.row.id)}>
                <span className="report__text">{t('defer')}</span>
              </button>
            </div>
          );
        }
      },
    },
    {
      field: 'created_at',
      headerName: t('tasks-th-create-date'),
      type: 'date',
      minWidth: 130,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.row.expired_at).toLocaleString('fr-FR');
        return (
          <Link
            className="listing__cell"
            href={
              params.row.registration.active == 0
                ? `/post/${params.row.registration_id}?active=0`
                : `/post/${params.row.registration_id}`
            }
          >
            {
              new Date(params.row.created_at)
                .toLocaleString('fr-FR')
                .split(' ')[0]
            }
          </Link>
        );
      },
      valueGetter: (params) =>
        `${new Date(params.row.created_at).toLocaleString('fr-FR')}`,
    },
    {
      field: 'type',
      headerName: t('tasks-th-type'),
      minWidth: 200,
      type: 'string',
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            className="listing__cell"
            href={
              params.row.registration.active == 0
                ? `/post/${params.row.registration_id}?active=0`
                : `/post/${params.row.registration_id}`
            }
          >
            {t(params.row.type.name)}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.type.name}`,
    },

    {
      field: 'caller_lastname',
      headerName: t('tasks-th-caller-lastname'),
      minWidth: '200',
      type: 'string',
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            className="listing__cell"
            href={
              params.row.registration.active == 0
                ? `/post/${params.row.registration_id}?active=0`
                : `/post/${params.row.registration_id}`
            }
          >
            {params.row.registration.caller_name}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.registration.caller_name}`,
    },
    {
      field: 'caller_firstname',
      headerName: t('tasks-th-caller-firstname'),
      minWidth: 200,
      type: 'string',
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            className="listing__cell"
            href={
              params.row.registration.active == 0
                ? `/post/${params.row.registration_id}?active=0`
                : `/post/${params.row.registration_id}`
            }
          >
            {params.row.registration.caller_firstname}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.registration.caller_firstname}`,
    },
    {
      field: 'name',
      headerName: t('tasks-th-lead-lastname'),
      minWidth: '200',
      type: 'string',
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            className="listing__cell"
            href={
              params.row.registration.active == 0
                ? `/post/${params.row.registration_id}?active=0`
                : `/post/${params.row.registration_id}`
            }
          >
            {params.row.registration.name}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.registration.caller_name}`,
    },
    {
      field: 'firstname',
      headerName: t('tasks-th-lead-firstname'),
      minWidth: 200,
      type: 'string',
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            className="listing__cell"
            href={
              params.row.registration.active == 0
                ? `/post/${params.row.registration_id}?active=0`
                : `/post/${params.row.registration_id}`
            }
          >
            {params.row.registration.firstname}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.registration.caller_firstname}`,
    },
    {
      field: 'ID',
      headerName: t('tasks-th-id'),
      width: '80',
      type: 'number',
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            className="listing__cell"
            href={
              params.row.registration.active == 0
                ? `/post/${params.row.registration_id}?active=0`
                : `/post/${params.row.registration_id}`
            }
          >
            {params.row.registration_id}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.registration_id}`,
    },

    {
      field: 'expired_at',
      headerName: t('tasks-th-due-date'),
      type: 'date',
      minWidth: 150,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.row.expired_at)
          .toLocaleString('fr-FR')
          .split(' ')[0];
        return (
          <Link
            className="listing__cell"
            href={
              params.row.registration.active == 0
                ? `/post/${params.row.registration_id}?active=0`
                : `/post/${params.row.registration_id}`
            }
          >
            {date}
          </Link>
        );
      },
      valueGetter: (params) =>
        `${new Date(params.row.expired_at).toLocaleString('fr-FR')}`,
    },
  ];

  function messageDataGrid() {
    if (loadingData === true) {
      return <Loading />;
    } else {
      return <p className="error__title">{t('empty-tasks-list')}</p>;
    }
  }

  const [step, setStep] = useState(1);
  const [selectedLead, setSelectedLead] = useState(null);
  const [leads, setLeads] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [results, setResults] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleRowClick = (row) => {
    setSelectedRowData({
      id: row.id,
      firstname: row.lead_firstname,
      lastname: row.lead_lastname,
    });
    setStep(2);
  };

  const handleTaskRowClick = (row) => {
    setSelectedRowData({
      id: row.registration_id,
      firstname: row.registration.firstname,
      lastname: row.registration.name,
    });
    setStep(2);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue !== '') {
      findLeads(inputValue);
    }
  };

  async function findLeads(search) {
    console.log(search);
    let paramsURL = search.split(' ');
    await axios
      .post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          'registrations/find',
        {
          terms: paramsURL,
        },
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        setResults(response.data);
        setLeads(response.data);
        setLoadingData(false);
      })
      .catch((error) => {
        console.error(paramsURL);
        setOpenMessage(true);
        setSeverity('error');
        setMessageText('There was an error retrieving the data');
      });
  }

  return (
    <div>
      <div className="wrapper">
        <header>
          <Nav />
        </header>
        <main>
          {openMessage && !open && (
            <NotificationBox
              num={errorCode}
              desc={openMessageText}
              severity={severity}
              full={openMessageFull}
            />
          )}
          <h1 className="text-6xl">
            {t('Welcome')} <span className="home__title">{NameCap}, </span>
          </h1>
          <div className="widget__list">
            {numberTaskUrgent !== 0 ? (
              <Widget
                url="/urgent"
                class="widget__title widget__title--red"
                img=""
                red="yes"
                title={t('widget-urgent-title')}
                num={numberTaskUrgent}
                text={t('widget-urgent-label-tasks')}
                type=""
              />
            ) : null}

            <Widget
              url="/listing"
              class="widget__title widget__title--leads"
              img=""
              title={t('widget-lead-title')}
              num={numberLead}
              text={t('widget-lead-label-new')}
              type="RESERVATION_URGENT"
              locked="0"
            />

            <Widget
              url="/listing"
              class="widget__title widget__title--complaints"
              img=""
              title={t('widget-complaints-title')}
              num={numberComplaint}
              text={t('widget-complaints-label-new')}
              type="COMPLAINT"
              locked="0"
            />

            <Widget
              url="/listing"
              class="widget__title widget__title--questions"
              img=""
              title={t('widget-questions-title')}
              num={numberQuestion}
              text={t('widget-questions-label-new')}
              type="GENERAL_QUESTION"
              locked="0"
            />
          </div>

          <div className="tasks">
            <div className="tasks__header">
              <h2 className="tasks__title">{t('tasks-title')}</h2>
            </div>
            <div className="tasks__filter">
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
              >
                <ToggleButton
                  value="all"
                  onClick={() => {
                    alignmentValue = 'all';
                    getData();
                    changeSorting(alignmentValue);
                  }}
                >
                  {t('tasks-filter-all-label')}
                </ToggleButton>
                <ToggleButton
                  value="today"
                  onClick={() => {
                    alignmentValue = 'today';
                    getData();
                    changeSorting(alignmentValue);
                  }}
                >
                  {t('tasks-filter-today-label')}
                </ToggleButton>
                <ToggleButton
                  value="tomorrow"
                  onClick={() => {
                    alignmentValue = 'tomorrow';
                    getData();
                    changeSorting(alignmentValue);
                  }}
                >
                  {t('tasks-filter-tomorrow-label')}
                </ToggleButton>
                <ToggleButton
                  value="week"
                  onClick={() => {
                    alignmentValue = 'week';
                    getData();
                    changeSorting(alignmentValue);
                  }}
                >
                  {t('tasks-filter-thisweek-label')}
                </ToggleButton>
              </ToggleButtonGroup>

              <div
                className="tasks__add"
                onClick={() => {
                  handleOpen();
                  setId(' ');
                  setNamePop(' ');
                  setSurnamePop(' ');
                  setDate(aTomorrow);
                  setDate(aTomorrow);
                  setStep(1);
                }}
              >
                <span>{t('tasks-add-label')}</span>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <circle
                    cx="16"
                    cy="16"
                    r="15.375"
                    fill="#F1F4F8"
                    stroke="#054991"
                    strokeWidth="1.25"
                  />
                  <line
                    x1="15.9585"
                    y1="10.625"
                    x2="15.9585"
                    y2="21.3751"
                    stroke="#054991"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                  />
                  <line
                    x1="10.625"
                    y1="16.0417"
                    x2="21.3751"
                    y2="16.0417"
                    stroke="#054991"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <Modal
                className={`tasks__modal ${
                  step === 2 ? 'tasks__modal tasks__modal--step-2' : ''
                }`}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="tasks__modal__container tasks__modal__container--small">
                  {openMessage && open && (
                    <NotificationBox
                      num={errorCode}
                      desc={openMessageText}
                      severity={severity}
                      full={openMessageFull}
                    />
                  )}
                  <div className="lead__header">
                    <div>
                      <span className="lead__header__step">
                        {step === 1 ? t('step-1-2') : t('step-2-2')}
                      </span>
                      <h3 className="lead__header__title">
                        {step === 1
                          ? t('tasks-add-search-lead')
                          : t('tasks-add-label')}
                      </h3>
                    </div>
                    <div
                      className="tasks__add tasks__cancel"
                      onClick={handleClose}
                    >
                      <span>{t('cancel-task')}</span>
                      <svg
                        className="tasks__cancel__button"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <circle
                          cx="16"
                          cy="16"
                          r="15.375"
                          fill="#F1F4F8"
                          stroke="#054991"
                          strokeWidth="1.25"
                        />
                        <line
                          x1="15.9585"
                          y1="10.625"
                          x2="15.9585"
                          y2="21.3751"
                          stroke="#054991"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                        />
                        <line
                          x1="10.625"
                          y1="16.0417"
                          x2="21.3751"
                          y2="16.0417"
                          stroke="#054991"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div>
                  {step === 1 && (
                    <div className="tasks__modal__content">
                      <div className="tasks__modal__search-input">
                        <TextField
                          placeholder={t('tasks-add-search-lead')}
                          onChange={(event) =>
                            setInputValue(event.target.value)
                          }
                          onKeyDown={handleKeyDown}
                          className="tasks__modal__search"
                        />
                        <Button
                          className={`${
                            inputValue == ''
                              ? 'tasks__modal__search-input-btn disabled'
                              : 'tasks__modal__search-input-btn'
                          }`}
                          onClick={() => {
                            findLeads(inputValue);
                          }}
                        >
                          <span className="incharge__text">
                            {t('listing-search-form-submit')}
                          </span>
                        </Button>
                      </div>

                      <span className="tasks__modal__search--small">
                        {t('tasks-add-search-lead-small')}
                      </span>
                      <TaskGridData
                        leads={leads}
                        inputValue={inputValue}
                        onRowClick={handleRowClick}
                        setSelectedRowData={setSelectedRowData}
                        setStep={setStep}
                        open={open}
                      />
                    </div>
                  )}
                  {step === 2 && selectedRowData && (
                    <div className="tasks__modal__content">
                      <div className="tasks__modal__column">
                        <div className="form__field">
                          <p className="form__field__title">
                            ID* {selectedRowData.firstname}{' '}
                            {selectedRowData.lastname}
                          </p>
                          <TextField
                            required
                            placeholder="ID"
                            variant="filled"
                            id="filled-basic"
                            onChange={(event) => setId(event.target.value)}
                            defaultValue={selectedRowData.id}
                          />
                        </div>
                        <div className="form__field">
                          <p className="form__field__title">
                            {t('tasks-th-type')}*
                          </p>
                          <Select
                            required
                            variant="filled"
                            id="filled-basic"
                            onChange={(event) => {
                              setType(event.target.value);
                            }}
                          >
                            {taskType?.length > 0 &&
                              taskType?.map((element, index) => {
                                return (
                                  <MenuItem
                                    key={element.id}
                                    value={element.name}
                                    onClick={() =>
                                      element.deferral_duration !== null
                                        ? setDisplayDate(false)
                                        : setDisplayDate(true)
                                    }
                                  >
                                    {index + 1 + '. ' + t(element.name)}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </div>

                        <div
                          className={`form__field form__field__date ${
                            displayDate === true
                              ? 'form__field__date--display'
                              : ''
                          }`}
                        >
                          <p className="form__field__title">{t('due-date')}*</p>

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              value={date}
                              onChange={handleChange}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>

                        <Button className="form__submit" onClick={postData}>
                          {t('add-button-text')}
                        </Button>
                      </div>
                    </div>
                  )}
                </Box>
              </Modal>
            </div>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openTime}
              onClose={handleCloseTime}
              closeAfterTransition
              className="time__modal"
            >
              <Box className="time__modal__container">
                <div className="lead__header">
                  <h3 id="transition-modal-title">{t('set-new-date')}</h3>
                  <div
                    className="tasks__add tasks__cancel"
                    onClick={handleCloseTime}
                  >
                    <svg
                      className="tasks__cancel__button"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="16"
                        cy="16"
                        r="15.375"
                        fill="#F1F4F8"
                        stroke="#054991"
                        strokeWidth="1.25"
                      />
                      <line
                        x1="15.9585"
                        y1="10.625"
                        x2="15.9585"
                        y2="21.3751"
                        stroke="#054991"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                      />
                      <line
                        x1="10.625"
                        y1="16.0417"
                        x2="21.3751"
                        y2="16.0417"
                        stroke="#054991"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="form__field">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      value={date}
                      onChange={handleChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <Button
                  className="form__submit form__submit--width-fit"
                  onClick={() => deferSpecific(taskNumber)}
                >
                  {t('update')}
                </Button>
              </Box>
            </Modal>
            <div className="tasks__grid">
              <Box sx={{ height: 500, width: '100%' }}>
                <DataGrid
                  rows={data}
                  columns={columns}
                  pageSize={100}
                  rowsPerPageOptions={[5]}
                  disableColumnMenu
                  getRowId={(row) => row.id}
                  components={{
                    NoRowsOverlay: messageDataGrid,
                  }}
                  sortingOrder={['desc', 'asc']}
                  sortModel={sortModel}
                  onSortModelChange={(newModel) => {
                    setSortModel(newModel);
                  }}
                  onRowClick={(params) => {
                    handleTaskRowClick(params.row);
                  }}
                />
              </Box>
            </div>
          </div>
          <SwitchLanguage />
        </main>
      </div>
      <footer className="footer"></footer>
    </div>
  );
}
