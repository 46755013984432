import Nav from '../components/Nav';
import '../assets/styles/main.scss';
import SwitchLanguage from '../components/SwitchLanguage';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { useAuth } from '../context/auth-context';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Link } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Locked from '../components/Locked';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import moment from 'moment';

import NotificationBox from '../components/NotificationBox';

export default function Listing() {
  const [loadingData, setLoadingData] = useState(true);
  document.title = 'Leadtool v3 | Emeis Belgium';
  const { t } = useTranslation();
  const inputID = useRef(null);
  const residentLastName = useRef(null);
  const residentFirstName = useRef(null);
  const callerLastName = useRef(null);
  const callerFirstName = useRef(null);
  moment.locale('fr');
  // const authContext = useContext(AuthContext);
  const { state, send } = useAuth();
  const keycloak = state.context?.keycloak;

  const [filtered, setfiltered] = useState(false);
  let filteredBtn = '';

  const [type, setType] = useState(true);

  const location = useLocation();
  const [data, setData] = useState({});
  const [setArchived, setSetArchived] = useState(false);

  function handleSwitchToggle() {
    setSetArchived(!setArchived);
  }

  async function getData() {
    const apiUrl =
      process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local';
    const endpoint =
      setArchived == true ? 'registrations?active=false' : 'registrations';

    await axios
      .get(apiUrl + endpoint, {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
      })
      .then((response) => {
        setData(response.data);
        setLoadingData(false);

        if (location.state != null) {
          if (location.state.type === 'RESERVATION_URGENT') {
            setData(
              response.data.filter(
                (item) =>
                  item.registration_type === 'RESERVATION_URGENT' &&
                  item.locked === 0
              )
            );
            setLoadingData(false);
            setfiltered(true);
          }

          if (location.state.type === 'COMPLAINT') {
            setData(
              response.data.filter(
                (item) =>
                  item.registration_type === 'COMPLAINT' && item.locked === 0
              )
            );
            setLoadingData(false);
            setfiltered(true);
          }

          if (location.state.type === 'GENERAL_QUESTION') {
            setData(
              response.data.filter(
                (item) =>
                  item.registration_type === 'GENERAL_QUESTION' &&
                  item.locked === 0
              )
            );
            setLoadingData(false);
            setfiltered(true);
          }
        }
      })
      .catch(function (error) {
        return (
          <NotificationBox
            type="error"
            num="1"
            title={t('notification-box-title')}
          />
        );
      });
  }

  useEffect(() => {
    if (loadingData) {
      getData();
    }

    if (setArchived === true) {
      getData();
    } else {
      getData();
    }
  }, [loadingData, setArchived]);

  if (filtered === true) {
    filteredBtn = (
      <Link href="/listing" className="toggleBtn--red toggleBtn--filtered">
        {t('filtered-list')}
      </Link>
    );
  }

  function handleClick() {
    const params = {
      'resident-lastname': residentLastName.current.value,
      'resident-firstname': residentFirstName.current.value,
      'caller-lastname': callerLastName.current.value,
      'caller-firstname': callerFirstName.current.value,
      'registration-ids': inputID.current.value,
      type: type,
    };

    if (params['type'] === 'all') {
      params['type'] = '';
    }

    for (const key of Object.keys(params)) {
      if (params[key] === null || params[key] === '' || params[key] === true) {
        delete params[key];
      }
    }

    const paramsQuery = new URLSearchParams(params).toString();
    let paramsURL = '';

    if (paramsQuery != null) {
      paramsURL = '?' + paramsQuery;
    }

    if (setArchived === true) {
      paramsURL = '?active=false&' + paramsQuery;
    }

    axios
      .get(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          'registrations' +
          paramsURL,
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        setLoadingData(false);
      });
  }

  // HANDLE LEAD

  async function handleEntry(rowID) {
    const residence = localStorage.getItem('selectedResidence');
    await axios
      .post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations/${rowID}/handle`,
        {
          residence: residence || '',
        },
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        getData();
      })
      .catch(function (error) {
        if (error.response.status <= 404) {
          alert(error.response.data + '   ' + error.response.status);
        }
      });
  }

  // DOWNLOAD EXCEL

  async function DownloadlData(rowID) {
    await axios
      .get(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations?type=excel`,
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
          responseType: 'blob',
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', moment()._d + '.xlsx');
        document.body.appendChild(link);
        link.click();
      });
  }

  const columns = [
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   type: 'number',
    //   width: 80,
    //   editable: false,
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell: (params) => {
    //     const status = params.row.registration_status;

    //     let circleClass = '';
    //     switch (status) {
    //       case 'OPEN':
    //         circleClass = 'circle--green';
    //         break;
    //       case 'taken':
    //         circleClass = 'circle--yellow';
    //         break;
    //       case 'closed':
    //         circleClass = 'circle--red';
    //         break;
    //       default:
    //         circleClass = '';
    //     }

    //     return (
    //       <div className={`circle--center ${circleClass}`}>
    //         <div className={`circle ${circleClass}`}></div>
    //       </div>
    //     );
    //   },
    // },

    {
      field: 'locked',
      headerName: t('listing-th-actions'),
      type: 'number',
      width: 140,
      editable: false,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        if (params.row.locked !== 1) {
          const rowID = params.row.id;
          let cellClass = 'listing__cell';
          if (params.row.targets > 1) {
            cellClass = 'listing__cell listing__cell--bcg';
          }
          return (
            <div className="margin-left cellClass">
              <button
                onClick={() => handleEntry(rowID)}
                className={
                  params.row.archived_at !== null
                    ? 'handle incharge single__footer__element--disabled'
                    : 'handle incharge'
                }
              >
                <span className="incharge__text">{t('listing-tr-handle')}</span>
              </button>
            </div>
          );
        } else {
          return (
            <div className="margin-left cellClass">
              <Locked />
            </div>
          );
        }
      },
    },
    {
      field: 'created_at',
      headerName: t('listing-th-created-at'),
      type: 'number',
      width: 100,
      headerAlign: 'left',
      align: 'left',
      type: 'date',
      valueGetter: (params) => {
        const date = new Date(params.row.created_at).toLocaleString('fr-FR');
        let cellClass = 'listing__cell';

        if (params.row.targets > 1) {
          cellClass = 'listing__cell listing__cell--bcg';
        }

        return (
          <Link
            className={cellClass}
            href={
              params.row.active == 0
                ? `/post/${params.row.id}?active=0`
                : `/post/${params.row.id}`
            }
          >
            {date.split(' ')[0]}
          </Link>
        );
      },
      valueGetter: (params) =>
        `${
          new Date(params.row.created_at).toLocaleString('fr-FR').split(' ')[0]
        }`,
    },
    {
      field: 'origin',
      headerName: t('origin'),
      width: '100',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      renderCell: (params) => {
        let cellClass = 'listing__cell';

        if (params.row.targets > 1) {
          cellClass = 'listing__cell listing__cell--bcg';
        }
        return (
          <Link
            className={cellClass}
            href={
              params.row.active == 0
                ? `/post/${params.row.id}?active=0`
                : `/post/${params.row.id}`
            }
          >
            {params.row.username}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.username}`,
    },
    {
      field: 'id',
      headerName: t('listing-th-id'),
      width: '100',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      renderCell: (params) => {
        let cellClass = 'listing__cell';
        if (params.row.targets > 1) {
          cellClass = 'listing__cell listing__cell--bcg';
        }

        return (
          <Link
            className={cellClass}
            href={
              params.row.active == 0
                ? `/post/${params.row.id}?active=0`
                : `/post/${params.row.id}`
            }
          >
            {params.row.id}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.id}`,
    },
    {
      field: 'type',
      headerName: t('listing-th-type'),
      width: '120',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      renderCell: (params) => {
        let cellClass = 'listing__cell';
        if (params.row.targets > 1) {
          cellClass = 'listing__cell listing__cell--bcg';
        }

        return (
          <Link
            className={cellClass}
            href={
              params.row.active == 0
                ? `/post/${params.row.id}?active=0`
                : `/post/${params.row.id}`
            }
          >
            {params.row.registration_type === 'RESERVATION_URGENT' &&
              t('listing-search-form-type-lead')}{' '}
            {params.row.registration_type === 'GENERAL_QUESTION' &&
              t('listing-search-form-type-question')}{' '}
            {params.row.registration_type === 'COMPLAINT' &&
              t('listing-search-form-type-complaint')}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.registration_type}`,
    },
    {
      field: 'caller_lastname',
      headerName: t('listing-th-caller-lastname'),
      width: 200,
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      renderCell: (params) => {
        let cellClass = 'listing__cell';
        if (params.row.targets > 1) {
          cellClass = 'listing__cell listing__cell--bcg';
        }

        return (
          <Link
            className={cellClass}
            href={
              params.row.active == 0
                ? `/post/${params.row.id}?active=0`
                : `/post/${params.row.id}`
            }
          >
            {params.row.caller_lastname}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.caller_lastname}`,
    },
    {
      field: 'caller_firstname',
      headerName: t('listing-th-caller-firstname'),
      width: 200,
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      renderCell: (params) => {
        let cellClass = 'listing__cell';
        if (params.row.targets > 1) {
          cellClass = 'listing__cell listing__cell--bcg';
        }

        return (
          <Link
            className={cellClass}
            href={
              params.row.active == 0
                ? `/post/${params.row.id}?active=0`
                : `/post/${params.row.id}`
            }
          >
            {params.row.caller_firstname}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.caller_firstname}`,
    },
    {
      field: 'caller_phone',
      headerName: t('listing-th-caller-phone'),
      type: 'number',
      width: 150,
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      renderCell: (params) => {
        let cellClass = 'listing__cell';
        if (params.row.targets > 1) {
          cellClass = 'listing__cell listing__cell--bcg';
        }

        return (
          <Link
            className={cellClass}
            href={
              params.row.active == 0
                ? `/post/${params.row.id}?active=0`
                : `/post/${params.row.id}`
            }
          >
            {params.row.caller_phone}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.caller_phone}`,
    },
    {
      field: 'lead_lastname',
      headerName: t('listing-th-lead-lastname'),
      width: '150',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      renderCell: (params) => {
        let cellClass = 'listing__cell';
        if (params.row.targets > 1) {
          cellClass = 'listing__cell listing__cell--bcg';
        }

        return (
          <Link
            className={cellClass}
            href={
              params.row.active == 0
                ? `/post/${params.row.id}?active=0`
                : `/post/${params.row.id}`
            }
          >
            {params.row.lead_lastname}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.lead_lastname}`,
    },
    {
      field: 'lead_firstname',
      headerName: t('listing-th-lead-firstname'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      renderCell: (params) => {
        let cellClass = 'listing__cell';
        if (params.row.targets > 1) {
          cellClass = 'listing__cell listing__cell--bcg';
        }

        return (
          <Link
            className={cellClass}
            href={
              params.row.active == 0
                ? `/post/${params.row.id}?active=0`
                : `/post/${params.row.id}`
            }
          >
            {params.row.lead_firstname}
          </Link>
        );
      },
      valueGetter: (params) => `${params.row.lead_firstname}`,
    },
  ];

  function messageDataGrid() {
    if (loadingData === true) {
      return <Loading />;
    } else {
      return <p className="error__title">{t('listing-no-data')}</p>;
    }
  }

  return (
    <div>
      <div className="wrapper">
        <header>
          <Nav />
        </header>

        <main className="listing">
          <div className="listing__title">
            <h1 className="text-6xl">Listing</h1>
            {filteredBtn}
            {state.context?.keycloak.hasRealmRole('sales-coach') && (
              <span className="listing__excel" onClick={() => DownloadlData()}>
                {t('download-excel-file')}
              </span>
            )}
            {(state.context?.keycloak.hasRealmRole('director') ||
              state.context?.keycloak.hasRealmRole('assistant-director') ||
              state.context?.keycloak.hasRealmRole('administrator') ||
              state.context?.keycloak.hasRealmRole('hq-agent') ||
              state.context?.keycloak.hasRealmRole('sales-coach')) && (
              <div className="switch">
                <input
                  id="one"
                  className="input"
                  type="checkbox"
                  checked={setArchived}
                  onChange={handleSwitchToggle}
                />
                <label htmlFor="one" className="slider"></label>
                <label
                  htmlFor="one"
                  className={`label${
                    setArchived === false ? ' label-disabled' : ''
                  }`}
                >
                  {t('only-inactive')}
                </label>
              </div>
            )}
          </div>

          <section className="listing__filters">
            <h3 className="listing__filters__title">
              {t('listing-search-form-title')}
            </h3>
            <div className="listing__filters__container">
              <div className="listing__filters__container--col1">
                <input
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleClick();
                    }
                  }}
                  className="listing__filters__input listing__filters__input--id"
                  name="registration-ids"
                  ref={inputID}
                  placeholder={t('listing-th-id')}
                />
                <input
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleClick();
                    }
                  }}
                  className="listing__filters__input"
                  name="resident-lastname"
                  ref={residentLastName}
                  placeholder={t('listing-th-lead-lastname')}
                />
              </div>

              <div className="listing__filters__container--col2">
                <input
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleClick();
                    }
                  }}
                  className="listing__filters__input"
                  name="resident-firstname"
                  ref={residentFirstName}
                  placeholder={t('listing-th-lead-firstname')}
                />
                <input
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleClick();
                    }
                  }}
                  className="listing__filters__input"
                  name="caller-lastname"
                  ref={callerLastName}
                  placeholder={t('listing-th-caller-lastname')}
                />
              </div>

              <div className="listing__filters__container--col3">
                <input
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleClick();
                    }
                  }}
                  className="listing__filters__input"
                  name="caller-firstname"
                  ref={callerFirstName}
                  placeholder={t('listing-th-caller-firstname')}
                />
                <Select
                  required
                  name="type-entry"
                  label="Type"
                  variant="filled"
                  id="filled-basic"
                  className="listing__filters__input listing__filters__input--select"
                  onChange={(event) => setType(event.target.value)}
                >
                  <MenuItem onChange={handleClick} value={'all'}>
                    {t('See all')}
                  </MenuItem>
                  <MenuItem onChange={handleClick} value={'lead'}>
                    {t('listing-search-form-type-lead')}
                  </MenuItem>
                  <MenuItem onChange={handleClick} value={'question'}>
                    {t('listing-search-form-type-question')}
                  </MenuItem>
                  <MenuItem
                    onChange={handleClick}
                    className="menuitem--last"
                    value={'complaint'}
                  >
                    {t('listing-search-form-type-complaint')}
                  </MenuItem>
                </Select>
              </div>

              <button
                className="listing__filters__input--submit"
                onClick={handleClick}
              >
                {' '}
                {t('listing-search-form-submit')}{' '}
              </button>
            </div>
          </section>

          <section className="listing__table">
            <Box sx={{ height: 1000, width: '100%' }}>
              <DataGrid
                rows={Array.from(data)}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[5]}
                disableColumnMenu
                getRowId={(row) => row.id}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: messageDataGrid,
                }}
              />
            </Box>
          </section>
          <SwitchLanguage />
        </main>
      </div>

      <footer className="footer"></footer>
    </div>
  );
}
